<template>
  <v-container class="gray lighten-4">
    <v-layout justify-center class="mt-15">
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12" max-width="400" min-width="250">
          <v-card-title
            class="cyan lighten-3 font-weight-black mt-8 d-flex justify-center"
          >
            {{ nickname }}
          </v-card-title>

          <v-card-text class="cyan lighten-4 py-4 white">
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <CodeInput
                  :loading="false"
                  :autoFocus="true"
                  :fields="4"
                  class="input"
                  v-on:change="onChange"
                  v-on:complete="onComplete"
                  ref="pincode"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center cyan lighten-3"
            ><v-btn color="deep-orange darken-2" class="font-weight-black" text @click="OnLogoff">
              ออกเข้ารหัสอื่น
              <v-icon class="ml-3">mdi-location-exit</v-icon></v-btn
            ></v-card-actions
          >
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CodeInput from "vue-verification-code-input";
export default {
  name: "Pincode",
  components: {
    CodeInput,
  },
  computed: {
    nickname() {
      return sessionStorage.getItem("nickname");
    },
  },
  methods: {
    onChange(v) {
      console.log("onChange ", v);
    },
    onComplete(v) {
      console.log("onComplete ", v);
      this.doVerify(v);
    },
    async doVerify(p_code) {
      let parameter = {
        pin_code: p_code,
      };

      await this.$store.dispatch("auth/verify_pincode", parameter).then(
        (response) => {
          if (response == "Success") {
            this.$router.push("/Menu_Data");
          }
          //console.log(response);
        },
        (error) => {
          switch (error) {
            // case "Error01":
            //   localStorage.removeItem("blog_token");
            //   this.$router.push("/Login");
            //   break;
            case "PINCODE_INCORRECT":
              //count
              this.pin_count++;
              this.$alert("รหัสไม่ถูกต้อง.", "ผิดพลาด", "error").then(() => {
                //this.$refs.pincode.values=['','','',''];
                // console.log(this.$refs.pincode.values[0]);
                // this.$refs.pincode.values[0].focus();
              });
              break;
            case "USER_NOTFOUND":
              localStorage.removeItem("blog_token");
              this.$router.push("/Login");
              break;
            case "USER_IS_SUSPEND":
              localStorage.removeItem("blog_token");
              this.$router.push("/Login");
              break;
            default:
              alert(error);
              break;
          }
          // count in database
          //   localStorage.removeItem("blog_token");
          //   alert(error);
          //   this.login_message(error);
        }
      );
    },
    OnLogoff(){
        this.$confirm("","กดยืนยันเพื่อออกจากระบบ","question")
        .then(() => {
          localStorage.removeItem("blog_token");
          sessionStorage.removeItem("host_id");
          sessionStorage.removeItem("period");
          sessionStorage.removeItem("nickname");
          this.$router.push("/Login");
        })
        .catch(() => {});
    }
  },
  data: () => ({
    code: "",
    pin_count: 0,
  }),
};
</script>

<style></style>
